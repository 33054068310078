import { useState } from 'react'

export const useHeader = () => {
  const [isVisibleMenu, setMenuVisibility] = useState<boolean>(false)

  const openMenu = () => setMenuVisibility(true)
  const closeMenu = () => setMenuVisibility(false)

  return {
    isVisibleMenu,
    openMenu,
    closeMenu,
  }
}
