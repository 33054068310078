import React from 'react'
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '../../store'
import { NotificationSettings, UIState } from '../../store/modules/ui/types'
import { toggleNotification } from '../../store/modules/ui/actions'

interface Props {}

const AppNotification: React.FC<Props> = () => {
  const { isVisible, message, duration, type } = useSelector<RootState, NotificationSettings>(
    (state) => (state.ui as UIState).notification,
  )

  const dispatch = useDispatch()

  const handleClose = () => {
    dispatch(toggleNotification({ isVisible: false }))
  }

  return (
    <Snackbar
      disableWindowBlurListener
      open={isVisible}
      autoHideDuration={duration}
      ClickAwayListenerProps={{
        onClickAway: () => ({}),
      }}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      onClose={handleClose}
    >
      <MuiAlert variant="filled" severity={type} onClose={handleClose}>
        {message}
      </MuiAlert>
    </Snackbar>
  )
}

export default AppNotification
