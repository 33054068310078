export enum LinkType {
  SHOP = 'shop',
  QUICK_PAY = 'quickPay',
}

export enum ChannelType {
  FB = 'fb',
  EMAIL = 'email',
  SMS = 'sms',
  COPY = 'copy',
  DASHBOARD = 'dashboard',
  QRCODE = 'qrcode',
  GATEWAY = 'gateway',
}
