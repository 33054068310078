import React from 'react'

export const EditIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.5858 0.585938L6.58579 14.5859L6 18.0002L9.41421 17.4144L23.4142 3.41436L20.5858 0.585938ZM5 1.00012H17.3432L15.3432 3.00012H5C3.89543 3.00012 3 3.89555 3 5.00012V19.0001C3 20.1047 3.89543 21.0001 5 21.0001H19C20.1046 21.0001 21 20.1047 21 19.0001V8.65701L23 6.65701V19.0001C23 21.2093 21.2091 23.0001 19 23.0001H5C2.79086 23.0001 1 21.2093 1 19.0001V5.00012C1 2.79098 2.79086 1.00012 5 1.00012Z"
      fill="currentColor"
    />
  </svg>
)
