import { useEffect, useState } from 'react'
import FingerprintJS from '@fingerprintjs/fingerprintjs'
import { StorageFactory } from '../services'
import { StorageType } from '../interfaces/storage'

const VISITOR_ID_KEY = '__charleston_visitor-id'

export const useVisitorId = () => {
  const [visitorId, setVisitorId] = useState<string>()

  useEffect(() => {
    ;(async () => {
      const storage = StorageFactory.getStorage(StorageType.LOCAL)
      const storedVisitorId = storage.getItem<string>(VISITOR_ID_KEY)
      if (storedVisitorId) {
        setVisitorId(storedVisitorId)
      } else {
        const agent = await FingerprintJS.load({ monitoring: false })
        const result = await agent.get()
        setVisitorId(result.visitorId)
        storage.setItem(VISITOR_ID_KEY, result.visitorId)
      }
    })()
  }, [])

  return {
    visitorId,
  }
}
