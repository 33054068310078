import { Participant } from './participant'
import { Registrant } from './registrant'
import { Season } from './season'

export enum InviteType {
  FACEBOOK = 'facebook',
  INVITE = 'invite',
  VISIT = 'visit',
}

export enum TransportType {
  EMAIL = 'email',
  TEXT = 'text',
  COPY = 'copy',
  FB = 'fb',
}

export interface Recipient {
  type: TransportType
  name: string
  address: string
  imageFile?: {
    bucket: string
    objectName: string
  }
}

export interface SendingDto {
  message?: string
  recipients: Recipient[]
  participants: number[]
}

export interface Invite {
  id: number
  email: string
  phone: string
  name: string
  registrantId: number
  participants: Participant[]
  type: InviteType
  createdAt: string
}

export interface InviteWithFingerprint extends Invite {
  fingerprint: string
}

export interface SupporterInvitationParticipant {
  id: string
  participantId: string
  supporterInviteId: string
}

export interface SupporterInvitationParticipantResponse {
  count: number
  rows: SupporterInvitationParticipant[]
}

export interface TokenContextResponse {
  registrant: Required<Registrant>
  participants: Participant[]
  season: Season
  currentSeason: Season
  transportType: TransportType
}

export interface LinkResponse {
  shortLink: string
  longLink: string
}
