import { useState, useCallback } from 'react'
import { ShortLinkController } from 'src/controllers/short-link'

export const useShortLink = () => {
  const [loading, setLoading] = useState(false)

  const findLink = useCallback(
    async (pathName: string) => {
      setLoading(true)
      const shortLink = encodeURI(pathName.substring(1, pathName.length))
      const {
        result: [error, result],
      } = await ShortLinkController.find({ shortLink })
      setLoading(false)
      return error ? null : result
    },
    [setLoading],
  )

  const startsWithHttp = (url: string) => url.startsWith('http')

  return {
    findLink,
    shortLinkLoading: loading,
    startsWithHttp,
  }
}
