import React from 'react'
import { useDispatch } from 'react-redux'
import { useForm, Controller } from 'react-hook-form'
import omit from 'lodash.omit'
import { MenuItem, Grid, Typography, Box, InputBaseComponentProps } from '@material-ui/core'
import { AppButton, Modal, TextField, Select } from 'src/components/ui'
import { useNotification } from 'src/hooks'
import { FormValues, useRegistrantEditing } from 'src/hooks/useRegistrantEditing'
import { Registrant } from 'src/interfaces/registrant'
import { Utils } from 'src/lib/utils'
import { Country } from 'src/interfaces/state'
import { updateRegistrant, saveAddress } from 'src/store/modules/registrant/actions'
import { NotificationType } from 'src/store/modules/ui/types'
import AppInputMask from '../ui/inputMask'

interface Props {
  directToSeller: boolean
  isVisible: boolean
  registrant: Registrant | null
  onClose: () => void
}

const SavingRegistrantInformation: React.FC<Props> = ({ directToSeller, isVisible, registrant, onClose }) => {
  const { getRules } = useRegistrantEditing()
  const dispatch = useDispatch()
  const { openNotification } = useNotification()
  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      email: registrant?.user.email || '',
      country: Country.USA,
      firstName: '',
      lastName: '',
      street: '',
      apartment: '',
      city: '',
      state: '',
      zipcode: '',
      phone: '',
    },
  })
  const watchCountry = watch('country')
  const countries = [Country.USA, Country.BERMUDA]
  const usaStates = Utils.getStates(Country.USA)
  const bermudaStates = Utils.getStates(Country.BERMUDA)

  const renderStateItem = (labelProperty: string, valueProperty: string) => (state: any) => (
    <MenuItem key={state[valueProperty]} value={state[valueProperty]}>
      {state[labelProperty]}
    </MenuItem>
  )

  const saveEmail = handleSubmit(async (data: FormValues) => {
    const [emailError] = await dispatch<any>(updateRegistrant({ email: data.email }))
    if (emailError) {
      openNotification(emailError, NotificationType.ERROR)
      return
    }
    if (directToSeller) {
      const address = omit(data, ['email'])
      address.phone = Utils.formatPhoneNumber(address.phone)
      const [addressError] = await dispatch<any>(saveAddress(address))
      if (addressError) {
        openNotification(addressError, NotificationType.ERROR)
        return
      }
    }
    onClose()
  })

  return (
    <Modal isOpen={isVisible} title="Please Add Your Details">
      <Grid container direction="column" alignItems="stretch">
        <Grid item>
          <Controller
            name="email"
            control={control}
            rules={{
              required: 'Email is required',
            }}
            render={({ field }) => (
              <TextField
                fullWidth
                label="Email address"
                {...field}
                error={Boolean(errors.email?.message)}
                helperText={errors.email?.message}
                variant="outlined"
                type="email"
              />
            )}
          />
        </Grid>
        {directToSeller && (
          <Grid container direction="column" alignItems="stretch" spacing={2}>
            <Grid item>
              <Box mt={3}>
                <Typography variant="h3">Shipping Address</Typography>
              </Box>
            </Grid>
            <Grid item>
              <Controller
                name="firstName"
                control={control}
                rules={{
                  required: getRules(watchCountry).firstName,
                }}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    label="First name"
                    {...field}
                    error={Boolean(errors.firstName?.message)}
                    helperText={errors.firstName?.message}
                    variant="outlined"
                  />
                )}
              />
            </Grid>
            <Grid item>
              <Controller
                name="lastName"
                control={control}
                rules={{
                  required: getRules(watchCountry).lastName,
                }}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    label="Last name"
                    {...field}
                    error={Boolean(errors.lastName?.message)}
                    helperText={errors.lastName?.message}
                    variant="outlined"
                  />
                )}
              />
            </Grid>
            <Grid item>
              <Controller
                name="country"
                control={control}
                rules={{
                  required: 'Country is required',
                }}
                render={({ field }) => (
                  <Select
                    {...field}
                    fullWidth
                    label="Country"
                    list={countries}
                    error={Boolean(errors.country)}
                    helperText={getRules(watchCountry).state as string}
                  />
                )}
              />
            </Grid>
            <Grid item>
              <Controller
                name="street"
                control={control}
                rules={{
                  required: getRules(watchCountry).street,
                }}
                render={({ field }) => (
                  <TextField
                    label="Street address"
                    {...field}
                    fullWidth
                    error={Boolean(errors.street?.message)}
                    helperText={errors.street?.message}
                    variant="outlined"
                  />
                )}
              />
            </Grid>
            <Grid item>
              <Controller
                name="apartment"
                control={control}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    label="Apt, suite, floor #"
                    {...field}
                    error={Boolean(errors.apartment?.message)}
                    helperText={errors.apartment?.message}
                    variant="outlined"
                  />
                )}
              />
            </Grid>
            <Grid item>
              <Controller
                name="city"
                control={control}
                rules={{
                  required: getRules(watchCountry).city,
                }}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    label="City"
                    {...field}
                    error={Boolean(errors.city?.message)}
                    helperText={errors.city?.message}
                    variant="outlined"
                  />
                )}
              />
            </Grid>
            <Grid item container spacing={2}>
              <Grid item xs>
                <Controller
                  name="state"
                  control={control}
                  rules={{
                    required: getRules(watchCountry).state,
                  }}
                  render={({ field }) => (
                    <Select
                      {...field}
                      fullWidth
                      list={watchCountry === Country.USA ? usaStates : bermudaStates}
                      labelProperty="name"
                      valueProperty="alphaCode"
                      iterator={renderStateItem}
                      label="State"
                      helperText={getRules(watchCountry).state as string}
                      error={Boolean(errors.state)}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={4}>
                <Controller
                  name="zipcode"
                  control={control}
                  rules={{
                    required: getRules(watchCountry).zipcode,
                  }}
                  render={({ field }) => (
                    <TextField
                      fullWidth
                      label="ZIP-code"
                      {...field}
                      error={Boolean(errors.zipcode?.message)}
                      helperText={errors.zipcode?.message}
                      variant="outlined"
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid item>
              <Controller
                name="phone"
                control={control}
                rules={{
                  required: getRules(watchCountry).phone,
                }}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    label="Mobile"
                    {...field}
                    error={Boolean(errors.phone?.message)}
                    helperText={errors.phone?.message}
                    variant="outlined"
                    InputProps={{
                      inputComponent: AppInputMask as React.ElementType<InputBaseComponentProps>,
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
        )}
        <Grid item>
          <Box clone mt={3}>
            <AppButton fullWidth size="large" variant="contained" onClick={saveEmail}>
              Save
            </AppButton>
          </Box>
        </Grid>
      </Grid>
    </Modal>
  )
}

export default SavingRegistrantInformation
