import { withStyles } from '@material-ui/core/styles'
import { Button as MButton } from '@material-ui/core'
import { KermitTheme } from '../../styles/theme.types'
import styled from 'styled-components'

export const AppButton = withStyles((theme: KermitTheme) => ({
  root: {
    borderRadius: 6,
    textTransform: 'initial',
  },
  outlined: {
    backgroundColor: 'transparent',
    color: theme.custom.colors.common.black,
    border: `2px solid ${theme.custom.colors.common.black}`,
    '&:hover': {
      backgroundColor: 'transparent',
      color: theme.custom.colors.common.black,
    },
    '&:disabled': {
      backgroundColor: 'transparent',
      color: theme.custom.colors.common.grey1,
    },
  },
  contained: {
    backgroundColor: theme.custom.colors.common.black,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.custom.colors.common.black,
      color: 'white',
    },
    '&:disabled': {
      backgroundColor: theme.custom.colors.common.grey1,
      color: 'white',
    },
    '&.Mui-disabled': {
      backgroundColor: theme.custom.colors.common.grey1,
      color: 'white',
    },
  },
}))(MButton)

export const BaseRedButton = styled(AppButton)`
  border-color: ${({ theme }: { theme: KermitTheme }) => theme.custom.colors.common.red};
  color: ${({ theme }: { theme: KermitTheme }) => theme.custom.colors.common.red};
  height: 42px;

  &:hover {
    color: ${({ theme }: { theme: KermitTheme }) => theme.custom.colors.common.red} !important;
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    height: 60px;
  }
`
