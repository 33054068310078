import { config } from '../config'
import { HttpMethod, TaskResponse } from '../interfaces/task'
import { ShortLinkFindPayload, ShortLinkFindResponse } from '../interfaces/shortLink'
import { Utils } from '../lib/utils'
import { TaskService } from '../services'

export class ShortLinkController {
  public static async find(queryParams: ShortLinkFindPayload): Promise<TaskResponse<ShortLinkFindResponse>> {
    const endpoint = config.api.endpoints.shortLink.find
    const params = Utils.buildQueryParams(queryParams)
    const url = `${endpoint}?${params}`
    return TaskService.execute({ url, method: HttpMethod.GET })
  }
}
