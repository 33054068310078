import React from 'react'
import InputMask from 'react-input-mask'

interface Props {
  inputRef: (ref: HTMLInputElement | null) => void
  mask: string
}

const AppInputMask: React.FC<Props> = ({ inputRef, mask = '+1 999-999-9999', ...other }) => (
  <InputMask
    {...other}
    ref={(ref: any) => {
      inputRef(ref ? ref.inputElement : null)
    }}
    type="tel"
    mask={mask}
  />
)

export default AppInputMask
