import React from 'react'

export const Close22 = () => (
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.4145 11.0003L21.354 2.0607L19.9398 0.646484L11.0003 9.58605L2.06069 0.646484L0.646476 2.0607L9.58604 11.0003L0.646439 19.9399L2.06065 21.3541L11.0003 12.4145L19.9399 21.3541L21.3541 19.9399L12.4145 11.0003Z"
      fill="currentColor"
    />
  </svg>
)
