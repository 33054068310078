import { Participant } from '../interfaces/participant'

export const useParticipant = () => {
  const getLastName = (participant: Participant, makeShort = false) => {
    if (!makeShort) return participant.lastName
    return `${participant.lastName[0].toUpperCase()}.`
  }

  return {
    getLastName,
  }
}
