import { useReducer, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { GTM_EVENT } from 'src/interfaces/gtm'
import { useGtm, useNotification } from './index'
import { Grade } from '../interfaces/grade'
import { Teacher } from '../interfaces/teacher'
import { Participant, ParticipantFile, UpdatingDto } from '../interfaces/participant'
import { GradeController, ParticipantController, TeacherController } from '../controllers'
import { logger } from '../lib'
import { NotificationType } from '../store/modules/ui/types'
import { RootState } from '../store'
import { RegistrantState } from '../store/modules/registrant/types'
import { User } from '../interfaces/user'
import { loadParticipants } from '../store/modules/participant/actions'
import { uploadAvatarFile } from '../helpers/uploadAvatarFile.ts'

export interface FormValues {
  firstName: string
  lastName: string
  isDisabled?: boolean
  gradeId: number | string
  teacherLastName: string
}

interface ReducerState {
  data: FormValues | null
  isSchool: boolean
}

export type ReducerAction =
  | { type: 'close'; payload: null }
  | { type: 'clear'; payload: null }
  | { type: 'create'; payload: null }
  | { type: ''; payload: FormValues }

export const useParticipantEditing = () => {
  const [gradeList, setGradeList] = useState<Grade[]>([])
  const [teacherList, setTeacherList] = useState<Teacher[]>([])
  const [avatar, setAvatar] = useState<Blob | null>(null)
  const [avatarUrl, setAvatarUrl] = useState<string | undefined>()
  const [isVisibleDuplicateModal, setVisibilityDuplicateModal] = useState<boolean>(false)
  const [duplicateParticipant, setDuplicateParticipant] = useState<Participant>()
  const [duplicateRegistrant, setDuplicateRegistrant] = useState<{
    user: User
  }>()
  const { openNotification, message } = useNotification()
  const history = useHistory()
  const dispatch = useDispatch()
  const { seasonOrganization, registrant } = useSelector<RootState, RegistrantState>((state) => state.registrant)
  const { sendDataToGTM } = useGtm()

  const loadGradeList = async (): Promise<void> => {
    const { result } = await GradeController.list({ sort: 'id:asc' })
    const [error, list] = result
    if (error) {
      logger.error(error)
      return
    }
    setGradeList(list)
  }

  const loadTeacherList = async (gradeId: number): Promise<void> => {
    if (!gradeId) return
    const { result } = await TeacherController.list({ gradeId })
    const [error, list] = result
    if (error) {
      logger.error(error)
      return
    }
    setTeacherList(list.rows)
  }

  const createTeacher = async (lastName: string, gradeId: number) => {
    const {
      result: [error],
    } = await TeacherController.create({ lastName, gradeId })
    if (error) {
      openNotification(error, NotificationType.ERROR)
    } else {
      openNotification(message.teacherCreatedSuccessfully, NotificationType.SUCCESS)
      await loadTeacherList(gradeId)
    }
  }

  const checkDuplicate = async (data: FormValues, isSchool: boolean) => {
    const checkDuplicatePayload = isSchool
      ? {
          firstName: data.firstName,
          lastName: data.lastName,
          teacherLastName: data.teacherLastName,
          gradeId: data.gradeId as number,
        }
      : {
          firstName: data.firstName,
          lastName: data.lastName,
        }
    const {
      result: [error, result],
    } = await ParticipantController.checkDuplicate(checkDuplicatePayload)
    if (error) {
      openNotification(error, NotificationType.ERROR)
      return
    }
    if (result.hasDuplicate) {
      setVisibilityDuplicateModal(true)
      setDuplicateParticipant(result.participant)
      setDuplicateRegistrant(result.registrant)
    } else {
      await createParticipant(data, isSchool)
    }
  }

  const createParticipant = async (data: FormValues, isSchool: boolean) => {
    try {
      const [err, files] = await uploadAvatar()
      if (err) {
        openNotification('Fail to load file', NotificationType.ERROR)
        logger.error(err)
        return
      }
      const participantFiles: ParticipantFile[] = files.map((file) => ({
        ...file,
        type: 'avatar',
      }))
      const createParticipantPayload = isSchool
        ? {
            firstName: data.firstName,
            lastName: data.lastName,
            teacherLastName: data.teacherLastName,
            gradeId: data.gradeId as number,
            files: participantFiles,
          }
        : {
            firstName: data.firstName,
            lastName: data.lastName,
            files: participantFiles,
          }
      const {
        result: [error],
      } = await ParticipantController.registration(createParticipantPayload)
      if (error) {
        openNotification(error, NotificationType.ERROR)
      } else {
        openNotification(message.participantCreatedSuccessfully, NotificationType.SUCCESS)
        sendDataToGTM({ event: GTM_EVENT.PAR_REG_COMPLETE })
        await dispatch(loadParticipants())
        history.push('/participant/list')
      }
    } catch (error) {
      openNotification(error as string, NotificationType.ERROR)
      logger.error(error)
    }
  }

  const uploadAvatar = async (): Promise<[Error | null, { bucket: string; objectName: string }[]]> => {
    if (avatar === null) {
      return [null, []]
    }

    return uploadAvatarFile(avatar, String(seasonOrganization?.organizationId))
  }

  const onChangeAvatar = ({ blob, url }: { blob: Blob | null; url: string | undefined }): void => {
    setAvatarUrl(url)
    setAvatar(blob)
  }

  const editParticipant = async (participant: Participant, data: FormValues) => {
    try {
      const [err, files] = await uploadAvatar()
      if (err) {
        openNotification('Fail to load file', NotificationType.ERROR)
        logger.error(err)
        return
      }
      const currentAvatar = participant.files.find((file) => file.type === 'avatar')
      const participantFiles: ParticipantFile[] = files.map((file) => {
        const context = { ...file, type: 'avatar' }
        return currentAvatar ? { ...context, id: currentAvatar?.id } : context
      })

      const participantUpdatePayload: UpdatingDto = {
        id: participant.id,
        firstName: data.firstName,
        lastName: data.lastName,
        disabled: data.isDisabled,
        files: participantFiles,
      }

      if (data.teacherLastName && data.gradeId) {
        participantUpdatePayload.teacherLastName = data.teacherLastName
        participantUpdatePayload.gradeId = parseInt(data.gradeId.toString(), 10)
      }

      const {
        result: [error],
      } = await ParticipantController.update(participantUpdatePayload)

      if (error) {
        openNotification(error, NotificationType.ERROR)
      } else {
        openNotification(message.participantUpdatedSuccessfully, NotificationType.SUCCESS)
        await dispatch(loadParticipants())
        history.push({
          pathname: '/participant/single',
          search: `?id=${participant.id}`,
        })
      }
    } catch (error) {
      openNotification(error as string, NotificationType.ERROR)
      logger.error(error)
    }
  }

  const loadParticipant = async (id: number): Promise<Participant | null> => {
    const {
      result: [error, result],
    } = await ParticipantController.single({ id })
    if (error) openNotification(error, NotificationType.ERROR)
    return result || null
  }

  const closeDuplicateModal = () => setVisibilityDuplicateModal(false)

  const [, reducerDispatch] = useReducer(reducer, {
    data: null,
    isSchool: false,
  })

  function reducer(state: ReducerState, action: ReducerAction): ReducerState {
    switch (action.type) {
      case 'close':
        closeDuplicateModal()
        return state
      case 'clear':
        closeDuplicateModal()
        history.push('/participant/list')
        return state
      case 'create':
        ;(async () => {
          await createParticipant(state.data as FormValues, state.isSchool as boolean)
        })()
        return state
      default:
        return {
          ...state,
          data: action.payload,
        }
    }
  }

  return {
    loadGradeList,
    loadTeacherList,
    loadParticipant,
    createTeacher,
    createParticipant,
    editParticipant,
    avatar,
    avatarUrl,
    uploadAvatar,
    onChangeAvatar,
    closeDuplicateModal,
    reducerDispatch,
    checkDuplicate,
    duplicateParticipant,
    duplicateRegistrant,
    registrant,
    isVisibleDuplicateModal,
    teacherList,
    gradeList,
  }
}
