import { TaskService } from 'src/services'
import { HttpMethod, TaskResponse } from 'src/interfaces/task'
import { RegisteringDto } from '../interfaces/visit'
import { config } from '../config'

export class VisitController {
  public static async register(dto: RegisteringDto): Promise<TaskResponse<boolean>> {
    const url = config.api.endpoints.visit.register
    return TaskService.execute({ url, method: HttpMethod.POST, body: dto })
  }
}
