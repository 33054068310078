import { config } from '../config'
import { ListQueryParams, Organization, OrganizationListResponse } from '../interfaces/organization'
import { HttpMethod, TaskResponse } from '../interfaces/task'
import { Utils } from '../lib/utils'
import { TaskService } from '../services'

export class OrganizationController {
  public static async list(queryParams: ListQueryParams): Promise<TaskResponse<OrganizationListResponse>> {
    const url = `${config.api.endpoints.organization.list}?${Utils.buildQueryParams(queryParams)}`
    return TaskService.execute({ url, method: HttpMethod.GET })
  }

  public static async single(queryParams: ListQueryParams): Promise<TaskResponse<Organization | null>> {
    const url = `${config.api.endpoints.organization.single}?${Utils.buildQueryParams(queryParams)}`
    return TaskService.execute({ url, method: HttpMethod.GET })
  }
}
