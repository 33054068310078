import styled from 'styled-components'
import 'react-popper-tooltip/dist/styles.css'

export const Tooltip = styled.div`
  padding: 16px;
`

export const TooltipArrow = styled.div``

export const TooltipContentWrapper = styled.div`
  width: 300px;
`
