import Rollbar from 'rollbar'

export const rollbarConfig = {
  accessToken: process.env.REACT_APP_ROLLBAR_TOKEN || '',
  captureUncaught: true,
  captureUnhandledRejections: true,
  environment: 'production',
  ignoredMessages: ['ChunkLoadError', 'Object Not Found Matching Id', 'Illegal invocation', 'JsonWebTokenError'],
} as Rollbar.Configuration

const rollbar = new Rollbar(rollbarConfig)

export default rollbar
