import { ImageController } from '../controllers'
import { UploadAvatarFile } from '../interfaces/upload.ts'

const uploadAvatarFile = async (avatar: Blob, organizationId?: string): Promise<[Error | null, UploadAvatarFile[]]> => {
  try {
    const formData = new FormData()
    formData.append('photo', avatar)
    formData.set('organizationId', organizationId)
    const {
      result: [error, info],
    } = await ImageController.upload(formData)

    if (error) {
      throw new Error(error)
    }

    return [null, [info]]
  } catch (err) {
    console.error(err)
    return [err as Error, []]
  }
}

export { uploadAvatarFile }
