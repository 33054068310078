import { config } from '../config'
import { HttpMethod, TaskResponse } from '../interfaces/task'
import { CreationDto, ListQueryParams, TeacherListResponse, Teacher } from '../interfaces/teacher'
import { Utils } from '../lib/utils'
import { TaskService } from '../services'

const DEFAULT_QUERY_PARAMS: ListQueryParams = {
  limit: 100,
}

export class TeacherController {
  public static async list(queryParams: ListQueryParams): Promise<TaskResponse<TeacherListResponse>> {
    queryParams = {
      ...DEFAULT_QUERY_PARAMS,
      ...queryParams,
    }
    const url = `${config.api.endpoints.teacher.list}?${Utils.buildQueryParams(queryParams)}`
    return TaskService.execute({ url, method: HttpMethod.GET })
  }

  public static async create(dto: CreationDto): Promise<TaskResponse<Teacher>> {
    const url = config.api.endpoints.teacher.creation
    return TaskService.execute({ url, method: HttpMethod.POST, body: dto })
  }
}
