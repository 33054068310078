import React, { FC, ReactNode } from 'react'
import { MuiThemeProvider, StylesProvider } from '@material-ui/core/styles'
import { ThemeProvider as StyledThemeProvider, createGlobalStyle } from 'styled-components'
import { theme } from './theme'
import './index.scss'

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    font-family: 'IBM Plex Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  * {
    box-sizing: border-box;
  }
`

export const ThemeProvider: FC<{ children: ReactNode }> = ({ children }) => (
  <MuiThemeProvider theme={theme}>
    <StylesProvider injectFirst>
      <StyledThemeProvider theme={theme}>
        <GlobalStyle />
        {children}
      </StyledThemeProvider>
    </StylesProvider>
  </MuiThemeProvider>
)
