import { Participant } from '../interfaces/participant'
import { Utils } from '../lib/utils'
import { InviteType } from '../interfaces/invite'
import { AlreadySentRecipient } from '../hooks/useInvite'

export class InvitesHelper {
  static getInvitesByParticipant(participant: Participant, invitations: AlreadySentRecipient[]) {
    if (!participant) return []
    const unique: AlreadySentRecipient[] = []
    invitations.forEach((invite) => {
      const isParticipantInvite = invite.participants.some((item) => item.id === participant.id)
      const hasInResultArray = unique.some(
        (item) =>
          Utils.toLowerCase(item.address) === Utils.toLowerCase(invite.address) &&
          Utils.toLowerCase(item.name) === Utils.toLowerCase(invite.name) &&
          item.type !== InviteType.VISIT,
      )
      if (isParticipantInvite && !hasInResultArray) {
        unique.push(invite)
      }
    })
    return unique
  }
}
