import { config } from '../config'
import { ListQueryParams, Grade } from '../interfaces/grade'
import { HttpMethod, TaskResponse } from '../interfaces/task'
import { Utils } from '../lib/utils'
import { TaskService } from '../services'

export class GradeController {
  public static async list(queryParams: ListQueryParams): Promise<TaskResponse<Grade[]>> {
    const url = `${config.api.endpoints.grade.list}?${Utils.buildQueryParams(queryParams)}`
    return TaskService.execute({
      url,
      method: HttpMethod.GET,
      timeoutDuration: 2 * 60 * 1000,
    })
  }
}
