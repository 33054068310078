import React from 'react'

export const Basket = () => (
  <svg width="19" height="22" viewBox="0 0 19 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.03518 0.5C7.36648 0.5 6.74201 0.834202 6.37108 1.3906L5.96482 2H1.5C0.947715 2 0.5 2.44772 0.5 3C0.5 3.55228 0.947715 4 1.5 4H1.55401L2.39659 19.1664C2.48491 20.7562 3.79977 22 5.39197 22H13.608C15.2002 22 16.5151 20.7562 16.6034 19.1664L17.446 4H17.5C18.0523 4 18.5 3.55228 18.5 3C18.5 2.44772 18.0523 2 17.5 2H13.0352L12.6289 1.3906C12.258 0.834202 11.6335 0.5 10.9648 0.5H8.03518ZM3.5571 4H15.4429L14.6065 19.0555C14.577 19.5854 14.1388 20 13.608 20H5.39197C4.86124 20 4.42295 19.5854 4.39351 19.0555L3.5571 4Z"
      fill="currentColor"
    />
  </svg>
)
