import React from 'react'

export const Copy = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15 14.1429V19C15 20.1046 14.1046 21 13 21H5C3.89543 21 3 20.1046 3 19V11C3 9.89543 3.89543 9 5 9H9.85714"
      stroke="currentColor"
      strokeWidth="2"
    />
    <rect x="9" y="3" width="12" height="12" rx="2" stroke="currentColor" strokeWidth="2" />
  </svg>
)
