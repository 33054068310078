import React, { FC, forwardRef } from 'react'
import { useMediaQuery, Box, BoxProps } from '@material-ui/core'
import styled from 'styled-components'
import { KermitTheme } from '../../styles/theme.types'

const StyledBox = styled(Box)<BoxProps & { ref?: any }>``

export const Content: FC<BoxProps> = forwardRef(({ children, ...rest }, ref) => {
  const isLarge = useMediaQuery((theme: KermitTheme) => theme.breakpoints.up('sm'))
  return (
    <StyledBox px={isLarge ? 9 : 3} {...rest} ref={ref}>
      {children}
    </StyledBox>
  )
})
