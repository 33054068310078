import 'react-slideshow-image/dist/styles.css'
import React from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { Provider as RollbarProvider, ErrorBoundary } from '@rollbar/react'
import { GTMProvider } from '@elgorditosalsero/react-gtm-hook'
import './styles/index.scss'
import App from './App'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
// import reportWebVitals from './reportWebVitals'
import { store } from './store'
import { config } from './config'
import { rollbarConfig } from './lib/rollbar'

const gtmParams = { id: config.gtmId }
const root = createRoot(document.getElementById('root')!)

root.render(
  <RollbarProvider config={rollbarConfig}>
    <ErrorBoundary>
      {config.gtmId ? (
        <GTMProvider state={gtmParams}>
          <Provider store={store}>
            <App />
          </Provider>
        </GTMProvider>
      ) : (
        <Provider store={store}>
          <App />
        </Provider>
      )}
    </ErrorBoundary>
  </RollbarProvider>,
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.register({
//   onUpdate: (registration) => {
//     if (registration && registration.waiting) {
//       registration.waiting.postMessage({ type: 'SKIP_WAITING' })
//     }
//   },
// })

// document.addEventListener('visibilitychange', () => {
//   if (!document.hidden && navigator.serviceWorker) {
//     navigator.serviceWorker.getRegistrations().then((regs) => {
//       regs.forEach((reg) => reg.update())
//     })
//   }
// })

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals()

serviceWorkerRegistration.unregister()
