import React, { FC } from 'react'
import { Box, BoxProps, Typography, useTheme } from '@material-ui/core'
import { KermitTheme } from 'src/styles/theme.types'

interface MessageProps {
  variant: 'success' | 'error' | 'info'
}

enum BackgroundColorMap {
  success = 'green',
  error = 'red',
  info = 'yellow1',
}

enum ColorMap {
  success = 'white',
  error = 'white',
  info = 'black',
}

const Message: FC<MessageProps & BoxProps> = ({ variant, children, ...rest }) => {
  const theme: KermitTheme = useTheme()
  return (
    <Box
      px={3}
      py={2}
      textAlign="center"
      bgcolor={theme.custom.colors.common[BackgroundColorMap[variant]]}
      color={theme.custom.colors.common[ColorMap[variant]]}
      {...rest}
    >
      <Typography variant="body1">{children}</Typography>
    </Box>
  )
}

export const ErrorMessage: FC<BoxProps> = ({ children, ...rest }) => (
  <Message variant="error" {...rest}>
    {children}
  </Message>
)

export const SuccessMessage: FC<BoxProps> = ({ children, ...rest }) => (
  <Message variant="success" {...rest}>
    {children}
  </Message>
)

export const InfoMessage: FC<BoxProps> = ({ children, ...rest }) => (
  <Message variant="info" {...rest}>
    {children}
  </Message>
)
