import { config } from '../config'
import { HttpMethod, TaskResponse } from '../interfaces/task'
import { TaskService } from '../services'

export class ImageController {
  public static async upload(image: FormData): Promise<TaskResponse> {
    const url = config.api.endpoints.image.uploading
    return TaskService.execute({
      url,
      method: HttpMethod.POST,
      body: image,
      isMultipart: true,
    })
  }
}
