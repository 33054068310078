import React, { FC } from 'react'

export const PersonIcon: FC = () => (
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.3423 24.1889C18.5021 25.3368 16.3285 26 14 26C11.6715 26 9.49788 25.3368 7.65776 24.189L8.07246 22.3817C8.33432 21.2406 8.51238 20.4722 8.71787 19.902C8.91414 19.3573 9.0988 19.104 9.31017 18.9356C9.52154 18.7673 9.80977 18.6439 10.3845 18.5744C10.9863 18.5017 11.775 18.5 12.9458 18.5H15.0542C16.225 18.5 17.0137 18.5017 17.6155 18.5744C18.1902 18.6439 18.4785 18.7673 18.6898 18.9356C18.9012 19.104 19.0859 19.3573 19.2821 19.902C19.4876 20.4722 19.6657 21.2406 19.9276 22.3817L20.3423 24.1889ZM22.09 22.8631L21.8769 21.9344L21.8633 21.8751C21.6186 20.8085 21.415 19.9213 21.1637 19.2239C20.8986 18.4882 20.5449 17.8563 19.9359 17.3712C19.3269 16.8862 18.632 16.6828 17.8555 16.5889C17.1197 16.4999 16.2093 16.5 15.1151 16.5H15.0542H12.9458H12.885C11.7907 16.5 10.8804 16.4999 10.1445 16.5889C9.36806 16.6828 8.67307 16.8862 8.0641 17.3712C7.45513 17.8563 7.10145 18.4882 6.83631 19.2239C6.58502 19.9213 6.38145 20.8085 6.13674 21.8751L6.12313 21.9344L5.91003 22.8631C3.50731 20.6687 2 17.5104 2 14C2 7.37258 7.37258 2 14 2C20.6274 2 26 7.37258 26 14C26 17.5104 24.4927 20.6687 22.09 22.8631ZM28 14C28 21.732 21.732 28 14 28C6.26801 28 0 21.732 0 14C0 6.26801 6.26801 0 14 0C21.732 0 28 6.26801 28 14ZM14 12.875C15.4728 12.875 16.6667 11.6811 16.6667 10.2083C16.6667 8.73557 15.4728 7.54167 14 7.54167C12.5272 7.54167 11.3333 8.73557 11.3333 10.2083C11.3333 11.6811 12.5272 12.875 14 12.875ZM14 14.875C16.5773 14.875 18.6667 12.7857 18.6667 10.2083C18.6667 7.631 16.5773 5.54167 14 5.54167C11.4227 5.54167 9.33333 7.631 9.33333 10.2083C9.33333 12.7857 11.4227 14.875 14 14.875Z"
      fill="currentColor"
    />
  </svg>
)
