import { Participant } from '../interfaces/participant'
import { Utils } from '../lib/utils'

export const useAvatar = () => {
  const getAvatarLink = (participant: Participant): string => {
    const avatar = participant.files.find((file) => file.type === 'avatar')
    if (avatar === undefined) return ''
    return Utils.buildImageLink(avatar.bucket, avatar.objectName)
  }

  return {
    getAvatarLink,
  }
}
