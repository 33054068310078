import { ChangeEvent, useCallback, useState } from 'react'
import debounce from 'lodash.debounce'
import { Utils } from '../lib/utils'
import { Country, State } from '../interfaces/state'
import { useNotification } from '.'
import { NotificationType } from '../store/modules/ui/types'
import { OrganizationController } from '../controllers'
import { Organization } from '../interfaces/organization'

export const useOrganization = () => {
  const [selectedState, setState] = useState<State | null>(null)
  const [selectedOrganization, setSelectedOrganization] = useState<Organization | null>(null)
  const [organizationName, setOrganizationName] = useState<string>('')
  const [organizationList, setOrganizationList] = useState<Organization[]>([])
  const states = Utils.getStates(Country.USA)
  const { openNotification, message } = useNotification()

  const loadOrganizationList = async (name: string, state: string) => {
    if (!name) return
    if (!state) {
      openNotification(message.selectState, NotificationType.WARNING)
      return
    }
    const {
      result: [error, list],
    } = await OrganizationController.list({
      name,
      state,
    })
    if (error) openNotification(error, NotificationType.ERROR)
    else setOrganizationList(list.rows)
  }

  const delayedLoadOrganizationList = useCallback(debounce(loadOrganizationList, 500), [])

  const onChangeState = (event: any, newValue: State | null) => {
    setState(newValue)
  }

  const onChangeOrganizationName = (event: ChangeEvent<any>) => {
    const name: string = event.target.value
    setOrganizationName(name)
    delayedLoadOrganizationList(name, selectedState?.alphaCode || '')
  }

  const buildOrganizationAddress = (
    organization: Organization,
    attributes: string[] = ['country', 'city', 'state', 'midasId'],
  ) => {
    const address: string[] = []
    attributes.forEach((attr) => (organization[attr] ? address.push(organization[attr]) : null))
    return address.join(', ')
  }

  const loadOrganizationByMidasId = async (midasId: number) => {
    if (!midasId) return null
    const {
      result: [error, organization],
    } = await OrganizationController.single({
      midasId,
    })
    if (error) {
      openNotification(error, NotificationType.ERROR)
      return null
    }
    setSelectedOrganization(organization)
    return organization
  }

  return {
    selectedState,
    organizationName,
    organizationList,
    states,
    selectedOrganization,
    loadOrganizationList,
    delayedLoadOrganizationList,
    onChangeOrganizationName,
    onChangeState,
    buildOrganizationAddress,
    setSelectedOrganization,
    setOrganizationName,
    loadOrganizationByMidasId,
  }
}
