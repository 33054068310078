import React from 'react'

export const DefaultAvatarIcon = () => (
  <svg width="52" height="66" viewBox="0 0 52 66" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M40.3333 51.7001L39.0359 46.3751C37.8573 41.5384 37.2681 39.12 35.4732 37.71C33.6784 36.3 31.1893 36.3 26.2111 36.3H25.1223C20.144 36.3 17.6549 36.3 15.8601 37.71C14.0653 39.12 13.476 41.5384 12.2975 46.3751L11 51.7001"
      stroke="currentColor"
      strokeWidth="2"
    />
    <ellipse cx="25.6667" cy="23.1" rx="8.43333" ry="8.8" stroke="currentColor" strokeWidth="2" />
  </svg>
)
