import { config } from '../config'
import { SendingDto, TokenContextResponse, LinkResponse, Recipient, InviteWithFingerprint } from '../interfaces/invite'
import { HttpMethod, TaskResponse } from '../interfaces/task'
import { Utils } from '../lib/utils'
import { TaskService } from '../services'

export class InviteController {
  public static async send(dto: SendingDto): Promise<TaskResponse<boolean>> {
    const url = config.api.endpoints.invite.sending
    return TaskService.execute({ url, method: HttpMethod.POST, body: dto })
  }

  public static async list(): Promise<TaskResponse<InviteWithFingerprint[]>> {
    const url = config.api.endpoints.invite.list
    return TaskService.execute({ url, method: HttpMethod.GET })
  }

  public static async getTokenContext(token: string): Promise<TaskResponse<TokenContextResponse>> {
    const url = `${config.api.endpoints.invite.tokenContext}?${Utils.buildQueryParams({ token })}`
    return TaskService.execute({ url, method: HttpMethod.GET })
  }

  public static async getInvitationLink(
    participantIds: number[],
    recipient: Recipient,
  ): Promise<TaskResponse<LinkResponse>> {
    const url = config.api.endpoints.invite.link
    return TaskService.execute({
      url,
      method: HttpMethod.POST,
      body: { participants: participantIds, recipient },
    })
  }

  public static async create(participantIds: number[]): Promise<TaskResponse<boolean>> {
    const url = config.api.endpoints.invite.create
    return TaskService.execute({
      url,
      method: HttpMethod.POST,
      body: { participants: participantIds },
    })
  }
}
