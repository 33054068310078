export const useClipboard = () => {
  const copyFromFakeInput = (link: string) => {
    const input = document.createElement('input')
    input.value = link
    input.style.display = 'hidden'
    document.body.appendChild(input)
    input.select()
    document.execCommand('copy')
    document.body.removeChild(input)
  }

  return {
    copyToClipboard: async (content: string | null) => {
      if (navigator.clipboard) {
        await navigator.clipboard.writeText(content || '')
      } else {
        copyFromFakeInput(content || '')
      }
    },
  }
}
