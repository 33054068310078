export enum GTM_EVENT {
  PAGE_VIEW = 'page_view',
  PAR_REG_FIRST_STEP = 'par_reg_first_step',
  PAR_REG_SECOND_STEP = 'par_reg_second_step',
  PAR_REG_UPLOAD_IMAGE = 'par_reg_upload_image',
  PAR_REG_SET_IMAGE = 'par_reg_set_image',
  PAR_REG_COMPLETE = 'par_reg_complete',
  EMAIL_INVITE = 'sent_email_invite',
  TEXT_INVITE = 'sent_text_invite',
  FACEBOOK = 'facebook_sharing',
  COPY = 'copy_sharing',
  REG_AUTH_LINK = 'registration_sent_auth_link',
  LOG_IN_AUTH_LINK = 'log_in_sent_auth_link',
  REG_WAITING_PAGE = 'registration_waiting_page',
  LOG_IN_WAITING_PAGE = 'log_in_waiting_page',
  USER_LOGIN = 'user_login',
  GUEST_GATEWAY_PICK_ORG = 'guest_gateway_pick_organization',
  GUEST_GATEWAY_SHOP_NOW = 'guest_gateway_shop_now',
  INVITE_GATEWAY_SHOP_NOW = 'invite_gateway_shop_now',
  USER_EDITING = 'user_editing',
  CLICK_SEND_TEXT = 'click_send_text',
  CLICK_SEND_EMAIL = 'click_send_email',
  SENT_DASHBOARD_LINK = 'sent_dashboard_link',
}
