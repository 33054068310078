import { VisitController } from 'src/controllers'
import { Participant } from 'src/interfaces/participant'
import { useCallback } from 'react'
import { useVisitorId } from './useVisitorId'

export const useVisit = () => {
  const { visitorId } = useVisitorId()

  const register = useCallback(
    async ({ url, participants, midasId }: { url: string; participants?: Participant[]; midasId?: number }) => {
      if (visitorId) {
        await VisitController.register({
          midasId,
          fingerprint: visitorId,
          url,
          userAgent: navigator.userAgent,
          referer: document.referrer,
          additionalParams: participants?.length
            ? {
                registrantId: participants[0].registrantId,
                participantCodes: participants.map((participant) => participant.code),
              }
            : undefined,
        })
      }
    },
    [visitorId],
  )

  return {
    register,
    visitorId,
  }
}
