import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { ValidationRule } from 'react-hook-form'
import omit from 'lodash.omit'
import { RegistrantController } from 'src/controllers'
import { GTM_EVENT } from 'src/interfaces/gtm'
import { Country } from '../interfaces/state'
import { useNotification, useGtm } from '.'
import { RootState } from '../store'
import { RegistrantState } from '../store/modules/registrant/types'
import { NotificationType } from '../store/modules/ui/types'
import { updateRegistrant, saveAddress } from '../store/modules/registrant/actions'
import { logger } from '../lib'
import { Utils } from '../lib/utils'

export interface FormValues {
  email: string
  userPhone: string
  street: string
  apartment: string
  city: string
  state: string
  zipcode: number | string
  firstName: string
  lastName: string
  country: string
  phone: string
}

interface Rule {
  firstName: string | ValidationRule<boolean> | undefined
  lastName: string | ValidationRule<boolean> | undefined
  street: string | ValidationRule<boolean> | undefined
  apartment?: string | ValidationRule<boolean> | undefined
  city: string | ValidationRule<boolean> | undefined
  state: string | ValidationRule<boolean> | undefined
  zipcode: string | ValidationRule<boolean> | undefined
  country: string | ValidationRule<boolean> | undefined
  phone: string | ValidationRule<boolean> | undefined
}

export const useRegistrantEditing = () => {
  const [inProgress, setInProgress] = useState<boolean>(false)
  const [checkUserPhone, setCheckUserPhone] = useState<boolean>(false)
  const [checkUserEmail, setCheckUserEmail] = useState<boolean>(false)
  const { registrant, seasonOrganization } = useSelector<RootState, RegistrantState>((state) => state.registrant)
  const dispatch = useDispatch()
  const { openNotification, message } = useNotification()
  const { sendDataToGTM } = useGtm()

  const getRules = (country: string): Rule => {
    const baseRules = {
      firstName: 'First name is required',
      lastName: 'Last name is required',
      street: 'Street is required',
      city: 'City is required',
      country: 'Country is required',
      phone: 'Please provide a valid mobile number',
      state: 'State is required',
      zipcode: 'Zipcode is required',
    }
    switch (country) {
      case Country.BERMUDA:
        return {
          ...baseRules,
          state: false,
          zipcode: false,
        }
      case Country.USA:
      default:
        return baseRules
    }
  }

  const hasPhone = async (phone: string, withProgress = true) => {
    if (withProgress) setCheckUserPhone(true)
    const formattedPhone = Utils.formatPhoneNumber(phone)
    const {
      result: [, hasUserByPhone],
    } = await RegistrantController.hasUser({
      phone: formattedPhone,
    })
    if (hasUserByPhone) {
      openNotification(message.phoneIsBusy, NotificationType.ERROR)
    }
    if (withProgress) setCheckUserPhone(false)
    return hasUserByPhone
  }

  const hasEmail = async (email: string, withProgress = true) => {
    if (withProgress) setCheckUserEmail(true)
    const {
      result: [, hasUserByEmail],
    } = await RegistrantController.hasUser({
      email,
    })
    if (hasUserByEmail) {
      openNotification(message.emailIsBusy, NotificationType.ERROR)
    }
    if (withProgress) setCheckUserEmail(false)
    return hasUserByEmail
  }

  const saveRegistrantData = async (data: FormValues) => {
    try {
      setInProgress(true)
      const formattedUserPhone = Utils.formatPhoneNumber(data.userPhone)
      const hasUserByPhone = await hasPhone(formattedUserPhone, false)
      if (hasUserByPhone) return

      const [personalError] = await dispatch<any>(updateRegistrant({ email: data.email, phone: formattedUserPhone }))
      if (personalError) {
        openNotification(personalError, NotificationType.ERROR)
        return
      }
      if (seasonOrganization?.directToSeller) {
        const address = omit(data, ['email', 'userPhone'])
        address.phone = Utils.formatPhoneNumber(address.phone)
        const [addressError] = await dispatch<any>(saveAddress(address))
        if (addressError) {
          openNotification(addressError, NotificationType.ERROR)
          return
        }
      }
      sendDataToGTM({ event: GTM_EVENT.USER_EDITING })
      openNotification(message.infoWasSaved, NotificationType.SUCCESS)
    } catch (error) {
      logger.error(error)
      openNotification(error as string, NotificationType.SUCCESS)
    } finally {
      setInProgress(false)
    }
  }

  return {
    getRules,
    saveRegistrantData,
    hasPhone,
    hasEmail,
    registrant,
    seasonOrganization,
    inProgress,
    checkUserPhone,
    checkUserEmail,
  }
}
