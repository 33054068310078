import React from 'react'

export const Close36 = () => (
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.4145 18.0003L29.3541 8.0607L27.9399 6.64648L18.0003 16.5861L8.06074 6.64648L6.64652 8.0607L16.5861 18.0003L6.64648 27.9399L8.0607 29.3541L18.0003 19.4145L27.9399 29.3541L29.3541 27.9399L19.4145 18.0003Z"
      fill="currentColor"
    />
  </svg>
)
