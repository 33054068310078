import { useCallback, useState } from 'react'
import { useNotification } from './useNotification.ts'
import { uploadAvatarFile } from '../helpers/uploadAvatarFile.ts'
import { NotificationType } from '../store/modules/ui/types.ts'
import { logger } from '../lib'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../store'
import { RegistrantState } from '../store/modules/registrant/types.ts'
import { RegistrantController } from '../controllers'
import { loadRegistrantData } from '../store/modules/registrant/actions.ts'

export const useUploadFbPostImage = () => {
  const [loading, setLoading] = useState(false)
  const { seasonOrganization } = useSelector<RootState, RegistrantState>((state) => state.registrant)
  const { openNotification, message } = useNotification()
  const dispatch = useDispatch()

  const uploadFbPostImage = useCallback(
    async ({ blob }: { blob: Blob }) => {
      setLoading(true)

      try {
        const [err, [uploadResult]] = await uploadAvatarFile(blob, String(seasonOrganization.organizationId))

        if (err) {
          openNotification('Fail to load file', NotificationType.ERROR)
          logger.error(err)
          return null
        }

        // NOTE: It is not needed any more. We don't want to persist the FB post image
        // TODO: remove it later
        // const {
        //   result: [error],
        // } = await RegistrantController.upsertFbPostImage({
        //   file: {
        //     bucket: uploadResult.bucket,
        //     objectName: uploadResult.objectName,
        //     type: 'fbPostImage',
        //   },
        // })

        // if (error) {
        //   openNotification(error, NotificationType.ERROR)
        // } else {
        //   dispatch(loadRegistrantData())
        // }

        return uploadResult
      } catch (error) {
        logger.error(error)
      } finally {
        setLoading(false)
      }
    },
    [dispatch, openNotification, seasonOrganization],
  )

  return { uploadFbPostImage, loading }
}
